import sendMail from "./utils/sendMail.js";

document.getElementById('returnToPrevPage')?.addEventListener('click', () => window.history.back());

document.addEventListener('DOMContentLoaded', () => {
    sendMail();
});

document.querySelectorAll('.form-group-animate')?.forEach(group => {
    const groupInput = group.querySelector('input') || group.querySelector('textarea');
    const groupLabelValue = group.querySelector('label').textContent;
    groupInput.placeholder = groupLabelValue;
    groupInput.addEventListener('focus', () => {
        group.classList.add('focus');
        groupInput.placeholder = '';
    });
    groupInput.addEventListener('blur', () => {
        if (!groupInput.value){
            group.classList.remove('focus');
            setTimeout(() => {
                groupInput.placeholder = groupLabelValue;
            }, 500);
            
        }
    });
});