export default async function sendMail() {
    document.getElementById('btn-submit')?.addEventListener('click', (event) => {
    event.preventDefault();
    const form = event.target.closest('form');
    const formData = new FormData(form);
    let formObject = {};
    formData.forEach((value, key) => formObject[key] = value);
    const json = JSON.stringify(formObject);

    // await response
    fetch('https://furlanandraz.com/mail', {
        method: 'POST',
        headers: {
                'Content-Type': 'application/json', // Specify JSON content type
            },
        body: json,
    });
        
    //retun success status
});
}